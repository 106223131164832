.bodySection {
  margin: auto;
  color: #333;
  /* background: #f5f5f5; */
}
body {
  font-family: inherit;
  font-size: 16px;
  color: #333;
  font-family: "Avenir Next Cyr";
  background-color: #fff !important;
  position: relative;
  z-index: 0; /* Ensure no conflicts */
}
html {
  position: relative;
  z-index: 0; /* Ensure no conflicts */
}
.adminControlApp {
  min-height: 1000px;
  /* display: inline-block; */
  /* background: #f5f5f5; */
}
.my-account-sec-bottom {
  width: 100%;
  gap: 30px;
  padding: 0 64px;
  padding-top: 68px;
}
.my-account-sec-top {
  margin-bottom: 30px;
  margin-top: 8px;
  border-bottom: 1px solid #cfd3d7;
}
.my-account-sec-top h2 {
  color: #000;
  /* Heading 06/H6/Semibold */
  font-family: "Poppins";
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
  line-height: 33px; /* 132% */
}
.my-account-sec-top p {
  color: #000;
  /* Paragraph 03/Regular */
  font-family: "Poppins";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px; /* 185.714% */
}
.layout-container {
  padding-top: 25px;
  width: 100%;
  max-width: 1440px;
  margin: auto;
}
.display-hidden {
  display: none !important;
}
@media (max-width: 575.98px) {
  .my-account-sec-bottom {
    padding: 0 16px;
    padding-top: 60px;
  }
  .mobile-video-padding {
    padding: 0px;
    padding-top: 60px;
  }
}
