.carousel-wrapper {
  width: 100%;
  overflow: hidden;
}

.carousel-slide {
  padding: 10px;
}

.carousel-item {
  position: relative;
  border-radius: 8px;
  overflow: hidden;
}

.carousel-image {
  width: 100%;
  height: 300px;
  object-fit: cover;
  border-radius: 8px;
}

.carousel-overlay {
  position: absolute;
  bottom: 10px;
  left: 10px;
  color: white;
  font-size: 16px;
  background: rgba(0, 0, 0, 0.5);
  padding: 5px 10px;
  border-radius: 10px;
  display: flex;
  align-items: center;
}

.carousel-name {
  margin-left: 10px;
}

.carousel-time {
  font-size: 12px;
  color: #ddd;
}
