@font-face {
  font-family: "AvenirNextBold";
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
  src: url("../css/AvenirNext/AvenirNextCyr-Bold.ttf") format("woff2");
}

@font-face {
  font-family: "AvenirNextLight";
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
  src: url("../css/AvenirNext/AvenirNextCyr-Light.ttf") format("woff2");
}

@font-face {
  font-family: "AvenirNextMedium";
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
  src: url("../css/AvenirNext/AvenirNextCyr-Medium.ttf") format("woff2");
}

@font-face {
  font-family: "AvenirNextRegular";
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
  src: url("../css/AvenirNext/AvenirNextCyr-Regular.ttf") format("woff2");
}

@font-face {
  font-family: "AvenirNextSemiBold";
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
  src: url("../css/AvenirNext/AvenirNextCyr-Demi.ttf") format("woff2");
}
