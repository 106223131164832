.top-header {
  position: fixed;
  width: 100%;
  top: 0px;
  left: 0px;
  z-index: 999;
  background-color: white;
}
.wrapper-hide-header {
  position: relative;
}
.layout-container {
  margin-top: 60px;
  position: relative;
  z-index: 1;
}
