@media screen and (max-width: 767px) {
  .mobl_header_styled {
    width: 100%;
    max-width: 100% !important;
  }
  .mobl_header_styled .allItemsSection {
    padding: 20px 20px !important;
  }
  .carousel-wrapper img {
    width: 100%;
    height: 100% !important; /* Keep the aspect ratio intact */
  }

  .banner_img_carsousel .slick-slide {
    height: 124px !important;
  }
  .carousel-wrapper {
    width: 100% !important; /* Make the carousel container responsive */
    max-width: 100% !important; /* Ensure it doesn't overflow beyond the viewport width */ /* Prevent overflowing content */
    padding-left: 5px !important;
    padding-right: 5px !important;
  }

  .carousel-wrapper img {
    width: 100% !important; /* Ensure the images fit the width of the carousel */
    height: 100% !important; /* Maintain aspect ratio */
  }
  .mobl_card_slid_main_wrapper {
    padding: 0px !important;
  }
  .row {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }

  .extension-footer-container {
    padding: 24px 10px !important;
  }
  .extension-footer-container .all-rights-reser {
    flex-wrap: wrap;
    align-items: center !important;
    justify-content: center !important;
  }
  .slick-dots {
    bottom: -35px !important;
  }
  .ant-carousel .slick-dots li {
    width: 12px !important;
    height: 12px !important;
    border: 1px solid #474747;
    border-radius: 50%;
  }
  .ant-carousel .slick-dots li.slick-active button {
    background: transparent !important;
    opacity: 1;
  }
  .ant-carousel .slick-dots li.slick-active {
    background: #000 !important;
  }
  .ant-carousel .slick-dots li button:hover {
    background: transparent !important;
  }

  .ant-carousel .slick-dots li button {
    background: transparent !important;
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }

  .mobile_header_drawer .ant-drawer-header {
    box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.04);
  }

  #navbarCollapse {
    background: #fff;
    box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.04);
    height: 120px !important;
  }
  #navbarCollapse .mobl_search_sec .ant-space-compact {
    width: 100%;
  }
  #navbarCollapse .mobl_search_sec {
    position: absolute;
    top: 65px;
    left: 0px;
    padding: 0px 15px;
    max-width: 100%;
    width: 100%;
  }
  .layout-container {
    padding-top: 0px !important;
  }
}
