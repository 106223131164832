.DDWM-header .ddwm-h-clear-search {
  color: #8a0f0f;
  font-family: "Oswald", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  text-transform: uppercase;
}
.dfs_down-filter-sec .modal-content {
  padding: 0px !important;
}
.dfs_down-filter-sec .modal-content .modal-body {
  padding: 10px 15px;
}
.dfs_down-filter-sec .modal-dialog {
  position: fixed;
  top: auto !important;
  right: auto;
  left: auto;
  bottom: -1px !important;
  max-width: 100% !important;
  max-height: 100vh;
  overflow: auto;
  width: 100%;
  margin: 0px;
}
.dfs_down-filter-sec .modal-dialog .modal-content {
  border-radius: 35px 35px 0px 0px;
}
.DDWM-header {
  width: 100%;
  background-color: #f8f7fa;
  border-radius: 35px 35px 0px 0px !important;
}
.DDWM-header .header-bar {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}
.DDWM-header .header-bar .header-inner-bar {
  border: 2px solid #d9d9d9;
  width: 40%;
}
.dfs_down-filter-sec .DDWM-header {
  display: block !important;
}
.DDWM-header .ddwm-headerText {
  font-family: "AvenirNextSemiBold";
  font-size: 18px;
  font-weight: 700;
  line-height: 22.05px;
  letter-spacing: 0.15000000596046448px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}
.dfs_down-filter-sec .modal-body {
  max-height: 90vh;
  overflow: auto;
}
.dfs_down-filter-sec .filterSection {
  margin: 0;
}

.dfs_down-filter-sec .modal-dialog {
  transition: transform 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) !important;
}

.dfs_down-filter-sec .modal-content {
  transition: border-radius 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) !important;
}

.dfs_down-filter-sec .modal-dialog.closing {
  transform: translateY(100%) !important;
}

.dfs_down-filter-sec .modal-content.closing {
  border-radius: 35px 35px 0px 0px !important;
}
.content-sec-closest-con h5 {
  font-size: 18px;
  font-family: "Montserrat", sans-serif;
}
.content-sec-closest-con p {
  font-size: 13px;
  font-weight: 400;
  margin-bottom: 15px;
  line-height: 16px;
  font-family: "Montserrat", sans-serif;
}
.content-sec-closest-con-sec p {
  font-size: 13px;
  font-weight: 400;
  margin-bottom: 5px;
  font-family: "Montserrat", sans-serif;
}
.content-sec-closest-con-sec input {
  width: 100%;
  box-shadow: none;
  padding: 10px;
  font-family: "Montserrat", sans-serif;
  line-height: 18px;
  border-radius: 4px;
  border: 1px solid #ccc;
  background: #fff;
  margin-bottom: 20px;
}
.green-color-txt {
  color: #219653;
  font-family: "Montserrat", sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 12px;
}
.DDwM-footer {
  width: 100%;
}
.DDwM-footer button {
  width: 50%;
}
.marketplace-dropdown {
  margin: 6px 0;
}
.offers-brand-dropdown.dfs_down-filter-sec {
  .modal-dialog {
    height: 80vh;
  }
}
@media (max-width: 575.98px) {
  .dfs_down-filter-sec .modal-content .modal-body {
    padding: 24px 24px;
  }
}
