.confirmation-modal .modal-dialog {
  width: 491px;
  height: 196px;
}

.confirmation-modal .modal-dialog .modal-content {
  min-height: 196px;
}

@media (max-width: 575.98px) {
  .confirmation-modal .modal-dialog {
    width: 100%;
    height: 196px;
  }
}
