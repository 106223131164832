.invite-modal,
.share-modal {
  width: 504px !important;
  height: 255px !important;
  /* left: 33% !important;
  top: 35% !important; */
  left: 0 !important;
  right: 0 !important;
  margin: auto;
  top: 0 !important;
  bottom: 0 !important;
  overflow-y: hidden !important;
}

.share-modal .justify-center {
  justify-content: center;
}

.share-modal .share-icons-sec img {
  margin-top: 17px;
  margin-left: 18px;
  cursor: pointer;
}
.share-icons-body {
  display: flex;
  justify-content: space-between;
}
.share-icons-sec button {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}
.share-icons-sec {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 8px;
  svg {
    width: 33px;
    height: 33px;
  }
}
.share-icons-sec p {
  margin-bottom: 0px;
  color: #000;
  font-family: "AvenirNextRegular";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 133.333% */
  cursor: pointer;
}
.invite-modal .invite-modal-header {
  color: #000;
  font-family: "AvenirNextMedium";
  font-size: 16px;
  font-style: normal;
  font-weight: 450;
  line-height: normal;
  display: flex;
  align-items: center;
}
.share-modal .modal-body {
  padding: 24px 24px 64px 24px;
}
.invite-modal-header svg {
  cursor: pointer;
}
@media (max-width: 575.98px) {
  .share-icons-body {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 24px;
  }
}
